import React from "react";
import Home from "../pages/Home";
import { Route, Routes } from "react-router-dom";
import Tos from "../pages/Tos";
import Roadmap from "../pages/Roadmap";
import Congrats from "../pages/Congrats";

import { useContractRead } from "wagmi";
import web3Config from "../web3.config";
import ComingSoon from "../pages/ComingSoon";

export default function Content() {
  const { data, isLoading, isError, error } = useContractRead({
    address: web3Config.contractAddress,
    abi: web3Config.abi,
    functionName: "totalSupply",
  });

  const inStock = data && data.toString() !== "520";

  return (
    <div className="content">
      <Routes>
        <Route path="/" element={<Home soldOut={!inStock} />} />
        <Route path="/tos" element={<Tos />} />
        <Route path="/roadmap" element={<Roadmap />} />
        <Route path="/congrats" element={<Congrats />} />
        <Route path="/coming-soon" element={<ComingSoon />} />
        {/* <Route path="/sold-out" element={<Home soldOut={false} />} /> */}
      </Routes>
    </div>
  );
}
