import React from "react";

import homeIcon from "../assets/images/home.svg";
import roadmapIcon from "../assets/images/roadmap.svg";
import magnifierIcon from "../assets/images/magnifier.svg";
import notificationBadgedIcon from "../assets/images/notificationBadged.svg";
import chartIcon from "../assets/images/chart.svg";
// import favoriteIcon from "../assets/images/favorite.svg";
// import settingsIcon from "../assets/images/settings.svg";

import SideBarIcon from "./SideBarIcon";

const Icons = [
  {
    icon: homeIcon,
    link: "/",
  },
  {
    icon: roadmapIcon,
    link: "/roadmap",
  },
  {
    icon: magnifierIcon,
    link: "/coming-soon",
  },
  {
    icon: notificationBadgedIcon,
    link: "/coming-soon",
  },
  {
    icon: chartIcon,
    link: "/coming-soon",
  },
];

export default function Sidebar() {
  return (
    <div className="sidebar">
      {Icons.map(({ icon, link }, index) => {
        return <SideBarIcon key={index} route={link} icon={icon} />;
      })}
    </div>
  );
}
