export const MusicOn = () => (
  <svg
    id="Group_92"
    data-name="Group 92"
    xmlns="http://www.w3.org/2000/svg"
    width="17.199"
    height="27.504"
    viewBox="0 0 17.199 27.504"
  >
    <defs>
      <clipPath id="clip-path">
        <rect
          id="Rectangle_1155"
          data-name="Rectangle 1155"
          width="17.199"
          height="27.504"
          fill="none"
        />
      </clipPath>
    </defs>
    <g
      id="Group_91"
      data-name="Group 91"
      transform="translate(0 0)"
      clip-path="url(#clip-path)"
    >
      <path
        id="Path_250"
        data-name="Path 250"
        d="M15.185,27.17,8.708,20.635H8.6V6.85h.108L15.185.319q.807-.807,2.014.43V26.765Q15.992,28,15.185,27.17M0,18.915V8.57A1.675,1.675,0,0,1,.5,7.36,1.64,1.64,0,0,1,1.72,6.85H6.88V20.635H1.72A1.636,1.636,0,0,1,.5,20.124,1.674,1.674,0,0,1,0,18.915"
        transform="translate(0 0)"
        fill="#fff"
      />
    </g>
  </svg>
);

export const ArrowLeft = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14.583"
    height="11.931"
    viewBox="0 0 14.583 11.931"
  >
    <path
      id="Path_247"
      data-name="Path 247"
      d="M7.954,0V3.977H0V7.954H7.954v3.977H9.28V10.606h1.326V9.28h1.326V7.954h1.326V6.629h1.326V5.3H13.257V3.977H11.931V2.651H10.606V1.326H9.28V0Z"
      transform="translate(14.583 11.931) rotate(180)"
      fill="#7b6c75"
    />
  </svg>
);

export const CloseSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24.469"
    height="24.469"
    viewBox="0 0 24.469 24.469"
  >
    <path
      id="Path_253"
      data-name="Path 253"
      d="M24.981,23.715a1.907,1.907,0,0,1-.577,1.4l-2.8,2.8a1.989,1.989,0,0,1-2.8,0l-6.055-6.055L6.691,27.917a1.907,1.907,0,0,1-1.4.577,1.907,1.907,0,0,1-1.4-.577l-2.8-2.8a1.989,1.989,0,0,1,0-2.8l6.055-6.055L1.089,10.2a1.989,1.989,0,0,1,0-2.8l2.8-2.8a1.907,1.907,0,0,1,1.4-.577,1.907,1.907,0,0,1,1.4.577l6.055,6.055L18.8,4.6a1.989,1.989,0,0,1,2.8,0l2.8,2.8a1.989,1.989,0,0,1,0,2.8l-6.055,6.055L24.4,22.315A1.907,1.907,0,0,1,24.981,23.715Z"
      transform="translate(-0.512 -4.025)"
      fill="#fff"
    />
  </svg>
);
