import React from "react";
import logo from "../assets/images/logo.png";
import Button from "./Button";
import { useSetRecoilState } from "recoil";
import { isDrawerOpen } from "../recoil";
import { Link } from "react-router-dom";

export default function MobNavbar() {
  const setDrawerOpen = useSetRecoilState(isDrawerOpen);
  return (
    <div className="mobnav-container mob">
      <Link to="/">
        <img src={logo} alt="logo" className="logo" />
      </Link>
      <div className="navbar-right flex al-ce">
        <Button variant={"secondary"}>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10.715"
              height="9.861"
              viewBox="0 0 10.715 9.861"
            >
              <g id="wallet-sharp" transform="translate(-2.937 -3.33)">
                <path
                  id="Path_238"
                  data-name="Path 238"
                  d="M3.431,4.967h9.193V3.712a.383.383,0,0,0-.458-.375l-8.8,1.446a.383.383,0,0,0-.287.25A.949.949,0,0,1,3.431,4.967Z"
                  transform="translate(-0.107 0)"
                  fill="#fff"
                />
                <path
                  id="Path_239"
                  data-name="Path 239"
                  d="M13.281,12H3.336a.383.383,0,0,0-.383.383v6.885a.383.383,0,0,0,.383.383h9.946a.383.383,0,0,0,.383-.383V12.383A.383.383,0,0,0,13.281,12ZM11,16.59a.765.765,0,1,1,.765-.765A.765.765,0,0,1,11,16.59Z"
                  transform="translate(-0.012 -6.459)"
                  fill="#fff"
                />
                <path
                  id="Path_240"
                  data-name="Path 240"
                  d="M2.937,12.315V8.884a.418.418,0,0,1,.369-.478c.842-.132,2.6-.2,2.6-.2a.764.764,0,0,1-.653.383v.586c.442,0,.749.562.749.562l-1.781,2.02Z"
                  transform="translate(0 -3.63)"
                  fill="#fff"
                />
              </g>
            </svg>
          </span>
        </Button>

        <Button variant={"secondary"} onClick={() => setDrawerOpen(true)}>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="11.019"
              height="9.861"
              viewBox="0 0 11.019 9.861"
            >
              <g id="menu" transform="translate(0 -5.333)">
                <path
                  id="Path_241"
                  data-name="Path 241"
                  d="M0,15.195V13.551H11.019v1.644Zm0-4.109V9.442H11.019v1.644ZM0,5.333H11.019V6.977H0Z"
                  fill="#fff"
                />
              </g>
            </svg>
          </span>
        </Button>
      </div>
    </div>
  );
}
